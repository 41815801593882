import { createApi } from '@reduxjs/toolkit/query/react';
import { RadiusApiError, apiBaseQuery } from './base';
import {
  AccountInvitationModel,
  CheckForm,
  Sex,
} from '../models/accountInvitation';
import { userDataFromAccountInvitationModel } from '../utils/login';
import { loadState } from '../utils/localStorage';
import { setEligibilityCheckForm } from '../store/eligibilityCheckSlice';
import dayjs from 'dayjs';

export interface CreateUserMutationParams {
  invitation: AccountInvitationModel;
}

interface CSVData {
  address_1: string;
  city: string;
  dob: string;
  enrolled_in_medical: string;
  first_name: string;
  gender: string;
  last_name: string;
  member_id: string;
  middle_initial: string;
  process_level: string;
  state: string;
  termination_date: string;
  zipcode: string;
}

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: apiBaseQuery('/login/api/v1'),
  tagTypes: ['AccountInvitation'],
  endpoints(builder) {
    return {
      checkEligibility: builder.query<boolean, CheckForm>({
        async queryFn(params, queryApi, extraOptions, baseQuery) {
          try {
            const dataResponse = await baseQuery({
              url: `/self-signup-eligibility`,
              method: 'POST',
              data: {
                memberId: params.id,
                lastName: params.lastName,
              },
            });

            if (dataResponse.data) {
              const member = dataResponse.data as CSVData;

              if (member) {
                await queryApi.dispatch(
                  setEligibilityCheckForm({
                    id: member.member_id,
                    firstName: member.first_name || params.firstName,
                    lastName: member.last_name || params.lastName,
                    dateOfBirth: member.dob
                      ? dayjs(member.dob).format('DD/MM/YYYY')
                      : '',
                    gender: (member.gender
                      ? member.gender === 'M'
                        ? 'male'
                        : 'female'
                      : null) as Sex,
                    street: member.address_1 || '',
                    city: member.city || '',
                    state: member.state || '',
                    zip: member.zipcode || '',
                  })
                );
              }

              return { data: !!member.member_id };
            }
          } catch (error) {
            return { data: false };
          }

          return {
            data: false,
          };
        },
      }),
      signUp: builder.mutation<{ userId: string }, CreateUserMutationParams>({
        async queryFn(params, queryApi, extraOptions, baseQuery) {
          const tenantName = loadState('tenantName', 'STRING') || 'sandbox_a';

          const createUserResponse = await baseQuery({
            url: `/sign-up`,
            method: 'POST',
            data: userDataFromAccountInvitationModel(
              params.invitation,
              tenantName
            ),
          });

          if (createUserResponse.error) {
            return { error: createUserResponse.error as RadiusApiError };
          }

          return {
            data: { userId: createUserResponse.data as string },
          };
        },
      }),
    };
  },
});

export const { useSignUpMutation, useLazyCheckEligibilityQuery } = loginApi;
